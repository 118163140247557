import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DIOM_BASED_URLS_FRONT_END, DIOM_HAYPER_PAY_URL, DIOM_PAYMENT_BASE_URL } from '../../config/url';
// import  } from 'react-router-dom';

const HayperPayCard = () => {
  const location = useLocation();
  const history = useHistory();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };


  const checkoutId = getQueryParam('checkoutId');
  const guestBookingId = getQueryParam('guestBookingId');
  const dataBrands = getQueryParam('brandData');
  const paymentType = getQueryParam('paymentType');




  useEffect(() => {
    if (!checkoutId) {
      console.error('checkoutId is missing from URL');
      return;
    }
    

    // Create the script element
    const script = document.createElement('script');
    script.src = `${DIOM_HAYPER_PAY_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;

    script.onload = () => {
      console.log('HyperPay script loaded successfully');
    };

    script.onerror = () => {
      console.error('Failed to load the HyperPay script');
    };

    // Append the script element to the document head
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [checkoutId]);

  // Check if payment is successful and navigate to userdetail page
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('id'); // Assumed query param for payment status

    if (paymentStatus) {
      history.push('/userdetail');
    }
  }, [location.search, history]);


  return (
    <form
    action={`${DIOM_PAYMENT_BASE_URL}/diom/pre-authorize-payments?paymentType=${paymentType}&paymentFor=BOOKING&redirectUrl=${DIOM_BASED_URLS_FRONT_END}/user-booking-details/${guestBookingId}`}
          class="paymentWidgets"
          data-brands={dataBrands}
      ></form>
  );
};

export default HayperPayCard;
