import React, { useEffect, useState } from "react";
import useGuestBookingDetail from "../hook/useGuestBookingDetail";
import {
  Button,
  Card,
  CardBody,
  List,
  CardTitle,
  Col,
  Row,
  Spinner as Loader,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import Select from "react-select";
import "../css/styles.css";
import { Link } from "react-router-dom";
import Spinner from "../../../../../components/Common/Loader";
import { useUserGuestBookingDetail } from "../hook/useUserGuestBookingDetail";
import Error404 from "../../../../Utility/Error404";
import moment from "moment";
const UserGuestBookingDetails = () => {
  const {
    userGuestBookingData,
    isUserGuestBookingDataLoading,
    formatDateTime,
    calculateTimeDifference,
    calculateTotalWithTax,
    createCheckOutForUserGuestBooking,
    toggle,
    dropdownOpen,
    isOpen,
    setIsOpen,
    cancelHandler,
    isGuestBookingCheckoutCreated,
    isAdminGuestBookingCancelLoading,
    remainingTime,
    bookingData,
    payNowHandler,
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    setPymentMethod,
    pymentMethod,
  } = useUserGuestBookingDetail();

  const isDisable =
    userGuestBookingData?.data?.data?.actionTaken === "DELETE_BOOKING" ||
    userGuestBookingData?.data?.data?.actionTaken === "DELETED_BY_SYSTEM" ||
    userGuestBookingData?.data?.data?.actionTaken === "CONFIRM_BOOKING";

  return (
    <div className="page-content">
      {isUserGuestBookingDataLoading ? (
        <Spinner />
      ) : (
        <>
          {userGuestBookingData?.data.data.actionTaken ===
          "DELETED_BY_SYSTEM" ? (
            <Error404 />
          ) : (
            <>
              <Row>
                <Col md={12}>
                  <div
                    className=" ml-4 "
                    style={{
                      backgroundColor: "black",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      style={{
                        width: "60px",
                        height: "50px",
                        size: "11px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      src="https://diom.s3.me-south-1.amazonaws.com/skE9icaj5Group%20%282%29.png"
                      alt="diom-logo"
                      title="diom logo"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={5}>
                  <Link to="/create-admin-booking" className="link">
                    <span className="locationbrandtitle  ">
                      Booking Details
                    </span>
                  </Link>
                </Col>

                {userGuestBookingData?.data?.data?.actionTaken ===
                "CONFIRM_BOOKING" ? (
                  <Col md={3} className="fontblackcolor">
                    <a
                      href={`${bookingData?.data?.data?.invoiceDetails[0]?.erpInvoiceUrl}`}
                      style={{ marginLeft: "25px", marginTop: "15px" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Booking Invoice
                    </a>
                  </Col>
                ) : (
                  <Col md={3} sm={12} xs={12}>
                    {" "}
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: 100,
                        marginLeft: "25px",
                      }}
                    >
                      RemainingTime:{remainingTime}
                    </p>
                  </Col>
                )}

                <Col md={2} sm={12} xs={12}></Col>

                <Col md={2}>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    disabled={
                      userGuestBookingData?.data?.data?.actionTaken ===
                      "CONFIRM_BOOKING"
                    }
                  >
                    <DropdownToggle
                      caret
                      style={{ width: "100%", marginTop: "5px" }}
                      color="success"
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem onClick={()=>setIsOpen(true)} 
        disabled={
          true
           }
        >Cancel Booking</DropdownItem> */}
                      <DropdownItem
                        onClick={payNowHandler}
                        disabled={isDisable}
                      >
                        Pay Now
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {/* <Button type='button' color="success" style={{width:"100%"}} onClick={createCheckOutForUserGuestBooking} > {isGuestBookingCheckoutCreated?<Loader/> :"Pay Now"} </Button> */}
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Card className="mt-3" style={{ padding: "15px" }}>
                    <CardBody>
                      <CardTitle tag="h1">Booking Details:</CardTitle>

                      <Row className="mt-3">
                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Location: </p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <p> {userGuestBookingData.data.data.businessName}</p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Resource Name: </p>
                        </Col>

                        <Col md={3} sm={6} xs={6}>
                          {userGuestBookingData.data.data.resourceTypeName}
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Booking Type: </p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          {userGuestBookingData.data.data.bookingType}
                        </Col>

                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Booking Start: </p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <p>
                            {" "}
                            {formatDateTime(
                              userGuestBookingData.data.data.fromTime
                            )}
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Booking End </p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          {formatDateTime(
                            userGuestBookingData.data.data.toTime
                          )}
                        </Col>

                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Billable Time: </p>
                        </Col>

                        <Col md={3} sm={6} xs={6}>
                          {calculateTimeDifference(
                            userGuestBookingData.data.data.fromTime,
                            userGuestBookingData.data.data.toTime
                          )}
                        </Col>

                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Booking Status </p>
                        </Col>

                        <Col md={3} sm={6} xs={6}>
                          <p>
                            {" "}
                            {userGuestBookingData.data.data.actionTaken ===
                            "DELETE_BOOKING" ? (
                              <span style={{ color: "red" }}>
                                DELETE_BOOKING
                              </span>
                            ) : (
                              userGuestBookingData.data.data.actionTaken
                            )}{" "}
                          </p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <p className="title"> Payment Status </p>
                        </Col>
                        <Col md={3} sm={6} xs={6}>
                          <p>
                            {" "}
                            {
                              userGuestBookingData.data.data
                                .bookingPaymentStatus
                            }{" "}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4} sm={12} xs={12}>
                  <Card className="mt-3" style={{ padding: "15px" }}>
                    <CardBody>
                      <CardTitle tag="h1">Payment Info</CardTitle>
                      <Row className="mt-3">
                        <Col md={6} sm={6} xs={6}>
                          <p className="title"> Sub Total </p>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                          <p className="title">
                            {`SAR ${userGuestBookingData.data.data.bookingSubTotal}/-`}{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} xs={6}>
                          <p className="title"> Discount </p>
                        </Col>

                        <Col md={6} sm={6} xs={6}>
                          <p>
                            {`SAR ${userGuestBookingData.data.data.discountedAmount}/-`}{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} xs={6}>
                          <p className="title"> VAT(15%) </p>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                          <p>
                            {" "}
                            {`SAR ${calculateTotalWithTax(
                              userGuestBookingData.data.data.bookingSubTotal
                            )}/-`}{" "}
                          </p>
                        </Col>
                      </Row>
                      {/* <hr></hr> */}
                      <Row>
                        <Col md={6} sm={6} xs={6}>
                          <p className="title"> Total Payable </p>
                        </Col>

                        <Col md={6} sm={6} xs={6}>
                          <p>
                            {`SAR ${userGuestBookingData.data.data.totalPayable}/-`}{" "}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* <span className="locationbrandtitle ml-4 ">
                Bank Details
              </span>
   <Row className="mt-2">
    <Col md={8}> 
<Card style={{padding:"15px"}}>
<List type="unstyled">
  <li >
  <span className="title">1.</span> <span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </span> 
  </li >
  <li >
  <span className="title">2.</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
  </li>
  <li>
  <span className="title">3.</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
  </li>
</List>

</Card>
          

</Col>
            <Col md={4} >
              </Col>
            </Row> */}
            </>
          )}
        </>
      )}
      {/* Camcel confirmation modal */}
      <Modal isOpen={isOpen}>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>Are you sure want to cancel this Booking</ModalBody>
        <ModalFooter>
          {" "}
          <Button color="success" onClick={() => setIsOpen(false)}>
            No
          </Button>
          <Button
            color="success"
            disabled={isAdminGuestBookingCancelLoading}
            onClick={() => cancelHandler()}
          >
            {isAdminGuestBookingCancelLoading ? (
              <Loader size="sm" color="light" />
            ) : (
              "Yes"
            )}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Payment Modal */}

      <Modal isOpen={isPaymentModalOpen}>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          <Label
            htmlFor="user"
            className="logininboxpanels"
            style={{ pointerEvents: "none" }}
          >
            Payment Method <span className="cp_starik">*</span>
          </Label>
          <Select
            placeholder=" Select Payment Method"
            options={[
              { label: "VISA MASTER", value: "VISA_MASTER" },
              { label: "MADA", value: "MADA" },
              { label: "APPLE PAY", value: "APPLE_PAY" },
              { label: "STC PAY", value: "STC_PAY" },
            ]}
            onChange={(opt) => setPymentMethod(opt.value)}
          ></Select>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Button color="success" onClick={() => setIsPaymentModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="success"
            disabled={!pymentMethod}
            onClick={() => createCheckOutForUserGuestBooking()}
          >
            {isGuestBookingCheckoutCreated ? (
              <Loader size="sm" color="light" />
            ) : (
              "Submit"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserGuestBookingDetails;
