import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useCustomQuery } from "../../../../../hooks/query";
import {
  getAdminGuestBooking,
  getBookingWithAdminTransactionReferenceId,
} from "../../../../../APIS/bookings";
import moment from "moment";
import { useState } from "react";
import {
  useCancelAdminGuestBookingByAdminMutation,
  useConfirmAdminGuestBookingMutation,
} from "./useAvailableResourceMutation";
import { toast } from "react-toastify";

export const useGuestBookingDetail = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmBookingModalOpen, setIsConfirmBookingModalOpen] =
    useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pymentMethod, setPymentMethod] = useState("");
  const [referenceId, setReferenceId] = useState(null);
  const { id } = useParams();
  // const history = useHistory();
  const token = localStorage.getItem("Token");
  //1) Get Admin Guest Booking

  const { data: guestBookingData, isLoading: isGuestBookingDataLoading } =
    useCustomQuery(
      ["AdminGuestBooking", id],
      () => getAdminGuestBooking(token, id),
      true
    );

  // 2) Cancel Booking
  const {
    cancelAdminGuestBookingMutateAsync,
    isAdminGuestBookingCancelLoading,
  } = useCancelAdminGuestBookingByAdminMutation(
    token,
    setIsOpen,
    "AdminGuestBooking"
  );
  // 3) Confirm Booking
  const {
    confirmAdminGuestBookingMutateAsync,
    isAdminGuestBookingConfirmedLoading,
  } = useConfirmAdminGuestBookingMutation(token, setIsConfirmBookingModalOpen);
  // 4) Check aleady booking exist with this transaction Id
  const { data: confirmedBookings, isLoading: isConfirmedBookings } =
    useCustomQuery(
      ["ConfirmBookings", `${referenceId}-${pymentMethod}`],
      () =>
        getBookingWithAdminTransactionReferenceId(
          token,
          setIsOpen,
          `${referenceId}-${pymentMethod}`
        ),
      referenceId ? true : false
    );

  // Function to format date and time
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("MMM DD YYYY, h:mm A");
  };

  // Function to calculate the difference in hours and minutes
  const calculateTimeDifference = (startTime, endTime) => {
    const start = moment(startTime);
    const end = moment(endTime);
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes() % 60);
    return `${hours} ${hours === 1 ? "hour" : "hours"} ${minutes} ${
      minutes === 1 ? "minute" : "minutes"
    }`;
  };

  function calculateTotalWithTax(amount) {
    const taxRate = 0.15; // 15% tax rate
    const tax = amount * taxRate; // Calculate tax

    return tax.toFixed(2);
  }

  function cancelHandler() {
    cancelAdminGuestBookingMutateAsync(id);
  }

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleAddPromoCode = () => {
    console.log("Add Promo Code selected");

    // Call your add promo code function here
  };

  const handlePayment = () => {
    const bodyData = {
      guestBookingId: id,
      paymentMethod: pymentMethod,
      adminTransactionReferenceId: `${referenceId}-${pymentMethod}`,
    };

    if (confirmedBookings?.data.length > 0) {
      return toast.error(
        `This transaction id already used with another booking`
      );
    }
    confirmAdminGuestBookingMutateAsync(bodyData);
  };

  return {
    id,
    guestBookingData,
    isGuestBookingDataLoading,
    formatDateTime,
    calculateTimeDifference,
    calculateTotalWithTax,
    isOpen,
    setIsOpen,
    cancelHandler,
    isAdminGuestBookingCancelLoading,
    dropdownOpen,
    setDropdownOpen,
    toggle,
    handlePayment,
    handleAddPromoCode,
    isConfirmBookingModalOpen,
    setIsConfirmBookingModalOpen,
    confirmAdminGuestBookingMutateAsync,
    isAdminGuestBookingConfirmedLoading,
    pymentMethod,
    setPymentMethod,
    referenceId,
    setReferenceId,
    isConfirmedBookings,
  };
};
